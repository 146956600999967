<template>
  <div>
    <form-section :title="$t('courierSet.labelSettings.title')">
      <v-row>
        <v-col>
          <select-field
            v-model="getDefaultValues.printAddress"
            :title="$t('courierSet.labelSettings.labelType')"
            rules="required"
            :filed-items="printAddresses"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <select-field
            v-model="getDefaultValues.printFormat"
            :title="$t('courierSet.labelSettings.printFileFormat')"
            rules="required"
            :filed-items="printFormates"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  data() {
    return {
      printFormates: [
        { id: 0, name: 'PDF' },
        { id: 1, name: 'EPL' },
      ],
      printAddresses: [
        { id: 1, name: 'SHIPPING' },
        { id: 2, name: 'RETURN' },
      ],
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getDefaultValues() {
      return this.getConfigurationSet();
    },
  },
  mounted() {
    if (this.isNew) {
      this.getDefaultValues.printAddress = 1;
      this.getDefaultValues.printFormat = 0;
    }
  },
};
</script>

<style scoped></style>
